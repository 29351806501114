import { FC, lazy, useEffect } from "react";
import {
  Redirect,
  Route,
  RouteProps,
  Router,
  Switch,
  useHistory,
} from "react-router-dom";
import Header from "./components/Header";
import Loading from "./components/Loading";
import SnackbarAlert from "./components/SnackbarAlert";

import { Box } from "@mui/material";

import "./App.css";
import "./i18n/config";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userInfoState, snackBarAlertState } from "./recoil/atom";
import { UserInfo } from "./typings/model";
import jwt from "jwt-decode";
import Footer from "./components/Footer";

const Home = lazy(() => import("./pages/Home"));
const UserProfile = lazy(() => import("./pages/UserProfile"));
const EditLand = lazy(() => import("./pages/EditLand"));
const Land = lazy(() => import("./pages/Land"));
const About = lazy(() => import("./pages/About"));
const Game = lazy(() => import("./pages/Game"));
const Market = lazy(() => import("./pages/Market"));
const Alpha = lazy(() => import("./pages/Alpha"));
const Coin = lazy(() => import("./pages/Coin"));
const Staking = lazy(() => import("./pages/Staking"));
const UnderConstruction = lazy(() => import("./pages/UnderConstruction"));
const FAQs = lazy(() => import("./pages/FAQs"));
const Blog = lazy(() => import("./pages/Blog"));
const BlogDetail = lazy(() => import("./pages/BlogDetail"));
const Announcements = lazy(() => import("./pages/Announcements"));
const AnnouncementsDetail = lazy(() => import("./pages/AnnouncementsDetail"));
const Policy = lazy(() => import("./pages/Policy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const HowTo = lazy(() => import("./pages/HowTo"));
const ContactUs = lazy(() => import("./pages/ContactUs"));

const App: FC = () => {
  const history = useHistory();

  const [userInfo, setUserInfo] = useRecoilState<UserInfo>(userInfoState);

  const setSnackBarAlertState = useSetRecoilState(snackBarAlertState);

  const RouteAuthenticated = ({ component: Component, path }: RouteProps) => {
    if (!localStorage.getItem("userInfo")) {
      setSnackBarAlertState({
        open: true,
        severity: "error",
        message: "Please login",
      });
      return <Redirect to="/" />;
    }

    return <Route component={Component} path={path} />;
  };

  const RouteUnauthenticated = ({ component: Component, path }: RouteProps) => {
    if (userInfo.isLogin) {
      //return <Redirect to="/" />;
    }

    return (
      <>
        <Route component={Component} path={path} /> <Footer />
      </>
    );
  };

  const RouteUnauthenticatedNoFooter = ({
    component: Component,
    path,
  }: RouteProps) => {
    return <Route component={Component} path={path} />;
  };

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      setUserInfo(JSON.parse(localStorage.getItem("userInfo") || "{}"));
    }
  }, []);

  return (
    <Box>
      <Loading />
      <SnackbarAlert></SnackbarAlert>
      <Header></Header>
      <Router history={history}>
        <Switch>
          <RouteUnauthenticated exact path="/" component={Home} />
          <RouteUnauthenticated exact path="/home" component={Home} />
          <RouteAuthenticated exact path="/user" component={UserProfile} />
          <RouteAuthenticated exact path="/editland/:id" component={EditLand} />
          <RouteUnauthenticatedNoFooter exact path="/land" component={Land} />
          <RouteUnauthenticated exact path="/about" component={About} />
          <RouteUnauthenticated exact path="/game" component={Game} />
          <RouteUnauthenticated exact path="/market" component={Market} />
          <RouteUnauthenticated exact path="/alpha" component={Alpha} />
          <RouteUnauthenticated exact path="/coin" component={Coin} />
          <RouteUnauthenticated exact path="/staking" component={Staking} />
          <RouteUnauthenticated exact path="/faqs" component={FAQs} />
          <RouteUnauthenticated exact path="/blog" component={Blog} />
          <RouteUnauthenticated exact path="/blog/:id" component={BlogDetail} />
          <RouteUnauthenticated
            exact
            path="/announcements"
            component={Announcements}
          />
          <RouteUnauthenticated
            exact
            path="/announcements/:id"
            component={AnnouncementsDetail}
          />
          <RouteUnauthenticated exact path="/policy" component={Policy} />
          <RouteUnauthenticated exact path="/howto" component={HowTo} />
          <RouteUnauthenticated exact path="/contactus" component={ContactUs} />
          <RouteUnauthenticated
            exact
            path="/termsofuse"
            component={TermsOfUse}
          />
          <RouteUnauthenticated
            exact
            path="/underconstruction"
            component={UnderConstruction}
          />
          <Redirect to={"/"} />
        </Switch>
      </Router>
    </Box>
  );
};

export default App;
