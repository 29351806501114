import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { SocialLink } from "../../typings/model";
import {
  faDiscord,
  faFacebookF,
  faInstagram,
  faLine,
  faTelegramPlane,
  faTwitter,
  faRedditAlien,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export interface SocialLinkProps {
  type: string;
  links: SocialLink[];
}

const SocialMenuItem: FC<SocialLinkProps> = ({ type, links }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const mapTypeSocail = (type: string) => {
    switch (type) {
      case "facebook":
        return faFacebookF;
      case "twitter":
        return faTwitter;
      case "line":
        return faLine;
      case "instagram":
        return faInstagram;
      case "discord":
        return faDiscord;
      case "telegram":
        return faTelegramPlane;
      case "reddit":
        return faRedditAlien;
      case "youtube":
        return faYoutube;
      default:
        return faTelegramPlane;
    }
  };

  return (
    <>
      {links.length > 1 ? (
        <>
          <IconButton
            id={`${type}-button`}
            aria-controls={open ? `${type}-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <FontAwesomeIcon
              style={{ fontSize: "24px", color: "#ffffff" }}
              icon={mapTypeSocail(type)}
            />
          </IconButton>

          <Menu
            id={`${type}-menu`}
            anchorEl={anchorEl}
            open={open}
            onClick={handleClose}
            onClose={handleClose}
            hideBackdrop={true}
            MenuListProps={{
              "aria-labelledby": `${type}-button`,
            }}
          >
            {links.map((link, idx) => (
              <MenuItem
                key={idx}
                component={Link}
                href={link.link}
                target="_blank"
                rel="noreferrer"
                onClick={handleClose}
              >
                {link.name}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <IconButton
          id={`${type}-button`}
          component={Link}
          href={links[0].link}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            style={{ fontSize: "24px", color: "#ffffff" }}
            icon={mapTypeSocail(type)}
          />
        </IconButton>
      )}
    </>
  );
};

export default SocialMenuItem;
