import React, { Suspense } from "react";

import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import App from "./App";
import LoadingSpinner from "./components/LoadingSpinner";
import { history } from "./recoil/history";
import { muiTheme } from "./muiTheme";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { GlobalStyle } from "./GlobalStyle";

const theme = createTheme();

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ThemeProvider theme={muiTheme}>
      <CssBaseline>
        <RecoilRoot>
          <Router history={history}>
            <Suspense fallback={<LoadingSpinner />}>
              <App />
            </Suspense>
          </Router>
        </RecoilRoot>
      </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
