/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Box,
  MenuItem,
  ListItemIcon,
  Menu,
  ListItemText,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router-dom";
import { Settings, Logout } from "@mui/icons-material";
import { styled } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import LogoutIcon from "@mui/icons-material/Logout";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  background: "#262626",
  "&:first-child": {
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  "&:last-child": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
  "&:hover": {
    background: "#F2050B",
  },
}));

interface UserNavProps {
  account: string;
}

const UserNav: FunctionComponent<UserNavProps> = ({ account }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("access_token");

    window.location.reload();
  };

  return (
    <Box
      sx={{
        pr: { xs: "0px", md: "20px" },
        cursor: "pointer",
        display: "flex",
        flexDirection: { xs: "row" },
      }}
    >
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar
          sx={{ width: 50, height: 50 }}
          src="/images/default-avatar.png"
        ></Avatar>
      </IconButton>

      {/* <Select
          sx={{ ml: 1, color: "white" }}
          value={userInfo?.userName}
          label={userInfo?.userName}
          IconComponent={() => <KeyboardArrowDownIcon />}
        >
          <MenuItem
            onClick={() => {
              history.replace("/user");
            }}
          >
            Profile
          </MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Select> */}

      <Menu
        anchorEl={anchorEl}
        hideBackdrop={true}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          sx: {
            padding: 0,
            borderRadius: "5px",
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "5px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <StyledMenuItem
          onClick={() => {
            history.replace("/user");
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faAddressCard} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ sx: { fontSize: "12px" } }}
            primary="My Profile"
          />
        </StyledMenuItem>
        <StyledMenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ sx: { fontSize: "12px" } }}
            primary="Log Out"
          />
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};

export default UserNav;
