import { createGlobalStyle } from "styled-components";
import UniwarsWoff from "./assets/fonts/Uniwars/UniwarsRegular.woff";
import UniwarsWoff2 from "./assets/fonts/Uniwars/UniwarsRegular.woff2";
import UniwarsTtf from "./assets/fonts/Uniwars/UniwarsRegular.ttf";
import BaiJamjureeTtf from "./assets/fonts/Bai_Jamjuree/BaiJamjuree-Regular.ttf";

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: Uniwars;
  src: url(${UniwarsWoff2}) format('woof2'),
       url(${UniwarsWoff}) format('woof'),
       url(${UniwarsTtf}) format('truetype');
}

@font-face {
  font-family: BaiJamjuree;
  src: url(${BaiJamjureeTtf}) format('truetype');
}

html, body {
  font-family: "bai-jamjuree";
  background-color: #000000;
}
`;
