import { atom } from "recoil";
import {
  Asset,
  BlockMap,
  PricePerPoint,
  WalletInfo,
  SnackbarAlert,
  UserInfo,
  InformationForm,
  Coordinate,
  ActionBlock,
} from "../typings/model";

export const loadingState = atom<boolean>({
  key: "loadingState",
  default: false,
});

export const languageState = atom<string>({
  key: "language",
  default: "en",
});

export const walletInfoState = atom<WalletInfo>({
  key: "walletInfo",
  default: { web3obj: "", account: "", balance: 0, networkId: "" },
});

export const selectedBuyBlockState = atom<BlockMap[]>({
  key: "selectedBuyBlock",
  default: [],
});

export const selectedBuyFromBlockState = atom<BlockMap[]>({
  key: "selectedBuyFromBlock",
  default: [],
});

export const selectedSellBlockState = atom<BlockMap[]>({
  key: "selectedSellBlock",
  default: [],
});

export const selectedInfoBlockState = atom<BlockMap[]>({
  key: "selectedInfoBlock",
  default: [],
});

export const selectedOnSellBlockState = atom<BlockMap[]>({
  key: "selectedOnSellBlock",
  default: [],
});

export const displayBlockState = atom<BlockMap[]>({
  key: "displayBlock",
  default: [],
});

export const pricePerPointState = atom<PricePerPoint | undefined>({
  key: "pricePerPoint",
  default: undefined,
});

export const pricePerPointBuyFromState = atom<number>({
  key: "pricePerPointBuyFrom",
  default: 0,
});

export const currencyBuyFromState = atom<string>({
  key: "currencyBuyFrom",
  default: "",
});

export const signInDialogState = atom<string>({
  key: "signInDialog",
  default: "",
});

export const openDialogState = atom<boolean>({
  key: "openDialog",
  default: false,
});

export const selectedInfoImageBlockState = atom<Asset | undefined>({
  key: "selectedInfoImageBlock",
  default: undefined,
});

export const snackBarAlertState = atom<SnackbarAlert>({
  key: "snackBarAlert",
  default: {
    open: false,
    severity: "success",
    message: "Success",
  },
});

export const userInfoState = atom<UserInfo>({
  key: "userInfo",
  default: {
    isLogin: false,
    userName: "",
    userId: 0,
    userEmail: "",
    isMailConfirm: false,
    userWallet: "",
  },
});

export const informationFormState = atom<InformationForm>({
  key: "informationForm",
  default: {},
});

export const selectedLocationState = atom<Coordinate | undefined>({
  key: "selectedLocation",
  default: undefined,
});

export const actionBlockState = atom<ActionBlock[]>({
  key: "actionBlock",
  default: [],
});
