import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import Web3 from "web3";
import {
  userInfoState,
  walletInfoState,
  snackBarAlertState,
} from "../../recoil/atom";
import { UserInfo, WalletInfo } from "../../typings/model";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import {
  loginWallet,
  updateWallet,
  validateLoginWallet,
} from "../../api/jakaverseApi";
import jwt from "jwt-decode";

const Wallet: FC = () => {
  const { t } = useTranslation();

  const [walletInfo, setWalletInfo] =
    useRecoilState<WalletInfo>(walletInfoState);
  const [userInfo, setUserInfo] = useRecoilState<UserInfo>(userInfoState);

  const resetUserInfo = useResetRecoilState(userInfoState);
  const resetWalletInfo = useResetRecoilState(walletInfoState);

  const setSnackBarAlertState = useSetRecoilState(snackBarAlertState);

  const loadWeb3 = async () => {
    setSnackBarAlertState({
      open: true,
      severity: "warning",
      message: "Connecting wallet...",
    });
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      setSnackBarAlertState({
        open: true,
        severity: "error",
        message: "Your browser does not support web3.",
      });
      return;
    }

    const web3 = window.web3;

    if (web3) {
      const accounts = await web3.eth.getAccounts();
      const balance = await web3.eth.getBalance(accounts[0]);
      const networkId = await web3.eth.net.getId().toString();
      const balanceunit = await web3.utils.fromWei(balance);

      if (accounts[0]) {
        setWalletInfo({
          //web3obj: web3,
          account: accounts[0],
          balance: balanceunit,
          networkId: networkId,
        });
      }

      const localStorageUser = JSON.parse(
        localStorage.getItem("userInfo") || "{}"
      );

      if (accounts[0] !== localStorageUser.userWallet) {
        const response = await loginWallet({ wallet: accounts[0] });
        await web3.eth.personal
          .sign(
            `I am signing to login Jakaverse : ${response.data}`,
            accounts[0]
          )
          .then(async (signstring: any) => {
            const valLogin = await validateLoginWallet({
              wallet: accounts[0],
              signature: signstring,
            });
            if (valLogin.data.access_token) {
              localStorage.setItem("access_token", valLogin.data.access_token);
              const user = jwt<UserInfo>(valLogin.data.access_token);
              user.isLogin = true;
              localStorage.setItem("userInfo", JSON.stringify(user));
              setUserInfo(user);
              setSnackBarAlertState({
                open: true,
                severity: "success",
                message: "Login success",
              });
            } else {
              setSnackBarAlertState({
                open: true,
                severity: "error",
                message: "Cannot login, please try again.",
              });
            }
          })
          .catch((e: any) => {
            setSnackBarAlertState({
              open: true,
              severity: "error",
              message: e.message,
            });

            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");

            resetUserInfo();
            resetWalletInfo();
          });
      }
    }
  };

  useEffect(() => {
    loadWeb3();
  }, []);

  return (
    <>
      {false && userInfo.userWallet && (
        <Box
          sx={{
            color: "#23F038",
            fontSize: { xs: "10px", md: "12px" },
            fontWeight: "900",
          }}
        >
          <CheckIcon sx={{ fontSize: "12px" }} /> Your Wallet Connected
        </Box>
      )}

      {!walletInfo.account && (
        <Button
          onClick={loadWeb3}
          sx={{
            fontSize: { xs: "12px", md: "14px" },
            mt: { xs: 0, md: 0 },
            fontWeight: "600",
            lineHeight: "30px",
            padding: { xs: "0px 4px", md: "4px 26px" },
            backgroundColor: "#F2050B",
            color: "#ffffff",
            textDecoration: "none",
            border: "2px solid #F2050B;",
            boxShadow: "0px 2px 20px rgba(242, 5, 119, 0.8)",
            "&:hover": {
              background: "none",
            },
          }}
        >
          {t("navbar.connectWallet")}
        </Button>
      )}
    </>
  );
};

export default Wallet;
