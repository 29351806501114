import { Backdrop, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC } from "react";

interface LoadingSpinnerProps {
  hide?: boolean;
  zIndex?: number;
}

const useStyles = makeStyles({
  backdrop: (props: LoadingSpinnerProps) => ({
    zIndex: props.zIndex || 1400,
  }),
});

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  hide = false,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <Backdrop
      transitionDuration={0}
      open={!hide}
      classes={{ root: classes.backdrop }}
    >
      <CircularProgress data-testid="loading" color="error" disableShrink size={48} />
    </Backdrop>
  );
};

export default LoadingSpinner;
