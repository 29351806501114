import config from "../config/config.json";
import usdt from "../config/Usdt.json";
import { ActionBlock, BlockMap, FilterMap, LandData } from "../typings/model";
import {
  ACTION_TYPE,
  BASE_COLOR_BLOCK,
  CURRENCY,
  CURRENCY_DISPLAY,
  INITAIL_FILTER_MAP,
  LAND_STATUS,
  PREMIUM_AVAILABLE_COLOR_BLOCK,
  PREMIUM_CLOSED_COLOR_BLOCK,
  PREMIUM_ON_SELL_COLOR_BLOCK,
  PREMIUM_OWNED_COLOR_BLOCK,
  REGULAR_AVAILABLE_COLOR_BLOCK,
  REGULAR_CLOSED_COLOR_BLOCK,
  REGULAR_ON_SELL_COLOR_BLOCK,
  REGULAR_OWNED_COLOR_BLOCK,
  SUMMAY_STATUS,
  TYPEBLOCK,
  YOUR_PREMIUM_COLOR_BLOCK,
  YOUR_REGULAR_COLOR_BLOCK,
} from "./constant";

export const ellipsisString = (str: string) => {
  if (str?.length > 4) {
    return str.substr(0, 4) + "..." + str.substr(str.length - 4, str.length);
  }
  return str;
};

export const changeLandDataValue = (lands: LandData[]) => {
  return lands.filter((l) => !SUMMAY_STATUS.AVAILABLE.includes(l.s));
};

export const convertBlockMap = (
  lands: LandData[],
  account: string,
  allowFeatureOwned?: boolean
): BlockMap[] => {
  if (account) {
    return lands.map((l) => {
      //fake
      let status = l.s;
      if (allowFeatureOwned) {
        if (LAND_STATUS.REGULAR_AVAILABLE === l.s) {
          status = LAND_STATUS.REGULAR_OWNED;
        } else if (LAND_STATUS.PREMIUM_AVAILABLE === l.s) {
          status = LAND_STATUS.PREMIUM_OWNED;
        }
      }

      let color = "";
      if (account === l.w) {
        if (
          [
            LAND_STATUS.REGULAR_AVAILABLE,
            LAND_STATUS.REGULAR_CLOSED,
            LAND_STATUS.REGULAR_ON_SELL,
            LAND_STATUS.REGULAR_OWNED,
          ].includes(status)
        ) {
          color = YOUR_REGULAR_COLOR_BLOCK;
        } else if (
          [
            LAND_STATUS.PREMIUM_AVAILABLE,
            LAND_STATUS.PREMIUM_CLOSED,
            LAND_STATUS.PREMIUM_ON_SELL,
            LAND_STATUS.PREMIUM_OWNED,
          ].includes(status)
        ) {
          color = YOUR_PREMIUM_COLOR_BLOCK;
        } else {
          color = mapStatusToColor(status);
        }
      } else {
        color = mapStatusToColor(status);
      }

      return {
        x: Number(l.x),
        y: Number(l.y),
        color: color,
        status: status,
        account: l.w,
        tokenId: l.t,
        landData: l.d,
        amount: l.a,
        currency: l.c,
      };
    });
  } else {
    return lands.map((l) => {
      //fake
      let status = l.s;
      if (allowFeatureOwned) {
        if (LAND_STATUS.REGULAR_AVAILABLE === l.s) {
          status = LAND_STATUS.REGULAR_OWNED;
        } else if (LAND_STATUS.PREMIUM_AVAILABLE === l.s) {
          status = LAND_STATUS.PREMIUM_OWNED;
        }
      }

      return {
        x: Number(l.x),
        y: Number(l.y),
        color: mapStatusToColor(status),
        status: status,
        account: l.w,
        tokenId: l.t,
        landData: l.d,
        amount: l.a,
        currency: l.c,
      };
    });
  }
};

export const convertBlockMapBackup = (
  lands: LandData[],
  account: string
): BlockMap[] => {
  if (account) {
    return lands.map((l) => {
      let color = "";
      if (account === l.w) {
        if (
          [
            LAND_STATUS.REGULAR_AVAILABLE,
            LAND_STATUS.REGULAR_CLOSED,
            LAND_STATUS.REGULAR_ON_SELL,
            LAND_STATUS.REGULAR_OWNED,
          ].includes(l.s)
        ) {
          color = YOUR_REGULAR_COLOR_BLOCK;
        } else if (
          [
            LAND_STATUS.PREMIUM_AVAILABLE,
            LAND_STATUS.PREMIUM_CLOSED,
            LAND_STATUS.PREMIUM_ON_SELL,
            LAND_STATUS.PREMIUM_OWNED,
          ].includes(l.s)
        ) {
          color = YOUR_PREMIUM_COLOR_BLOCK;
        } else {
          color = mapStatusToColor(l.s);
        }
      } else {
        color = mapStatusToColor(l.s);
      }

      return {
        x: Number(l.x),
        y: Number(l.y),
        color: color,
        status: l.s,
        account: l.w,
        tokenId: l.t,
        landData: l.d,
        amount: l.a,
        currency: l.c,
      };
    });
  } else {
    return lands.map((l) => ({
      x: Number(l.x),
      y: Number(l.y),
      color: mapStatusToColor(l.s),
      status: l.s,
      account: l.w,
      tokenId: l.t,
      landData: l.d,
      amount: l.a,
      currency: l.c,
    }));
  }
};
export const mapStatusToColor = (status: string) => {
  switch (status) {
    case LAND_STATUS.REGULAR_AVAILABLE:
      return REGULAR_AVAILABLE_COLOR_BLOCK;

    case LAND_STATUS.REGULAR_OWNED:
      return REGULAR_OWNED_COLOR_BLOCK;

    case LAND_STATUS.REGULAR_CLOSED:
      return REGULAR_CLOSED_COLOR_BLOCK;

    case LAND_STATUS.REGULAR_ON_SELL:
      return REGULAR_ON_SELL_COLOR_BLOCK;

    case LAND_STATUS.PREMIUM_AVAILABLE:
      return PREMIUM_AVAILABLE_COLOR_BLOCK;

    case LAND_STATUS.PREMIUM_OWNED:
      return PREMIUM_OWNED_COLOR_BLOCK;

    case LAND_STATUS.PREMIUM_CLOSED:
      return PREMIUM_CLOSED_COLOR_BLOCK;

    case LAND_STATUS.PREMIUM_ON_SELL:
      return PREMIUM_ON_SELL_COLOR_BLOCK;
    default:
      return BASE_COLOR_BLOCK;
  }
};

export const mapStatusToTypeBlock = (status: string) => {
  switch (status) {
    case LAND_STATUS.REGULAR_AVAILABLE:
    case LAND_STATUS.REGULAR_OWNED:
    case LAND_STATUS.REGULAR_CLOSED:
    case LAND_STATUS.REGULAR_ON_SELL:
      return TYPEBLOCK.REGULAR;

    case LAND_STATUS.PREMIUM_AVAILABLE:
    case LAND_STATUS.PREMIUM_OWNED:
    case LAND_STATUS.PREMIUM_CLOSED:
    case LAND_STATUS.PREMIUM_ON_SELL:
      return TYPEBLOCK.PREMIUM;
    default:
      return TYPEBLOCK.REGULAR;
  }
};

export const convertToDisplayDialog = (blocks: BlockMap[]): BlockMap[] =>
  blocks.map((block) => ({
    ...block,
    status: mapStatusToTypeBlock(block.status),
  }));

export const convertToDisplayBlock = (lands: LandData[]): BlockMap[] =>
  lands.map((land) => ({
    x: land.x,
    y: land.y,
    landData: land.d,
    status: mapStatusToTypeBlock(land.s),
  }));

export const convertToActionBlock = (lands: LandData[]): ActionBlock[] =>
  lands.map((land) => ({
    x: land.x,
    y: land.y,
    tokenId: land.t,
    landData: land.d,
    status: mapStatusToTypeBlock(land.s),
  }));

export const removeLastComma = (str: string) => {
  return str.replace(/,(\s+)?$/, "");
};

export const getAddressFromNetworks = (object: any, id: any) => {
  for (const [tmpId, value] of Object.entries(object)) {
    if (tmpId === id) {
      return (value as any).address;
    }
  }
};

export const storage = (key: string) => {
  return sessionStorage.getItem(key) || "";
};

export const downloadBase64Image = (fileName: string, linkSource: string) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};

export const getCurrency = (address: string, forDisplay?: boolean) => {
  let currency = "";
  let displayCurrency = "";

  switch (address.toLowerCase()) {
    case usdt.address.toLowerCase():
      currency = CURRENCY.USDT;
      displayCurrency = CURRENCY_DISPLAY.USDT;
      break;

    default:
      currency = CURRENCY.JK;
      displayCurrency = CURRENCY_DISPLAY.JK;
  }

  return forDisplay ? displayCurrency : currency;
};

export const isNearBlocks = (lands: any, sizeX: number, sizeY: number) => {
  if (lands.length < sizeX * sizeY) return false;

  let data = lands.map((land: any) => ({ x: land.x, y: land.y }));

  const minX = Math.min(...data.map((d: any) => d.x));
  const maxX = Math.max(...data.map((d: any) => d.x));
  const minY = Math.min(...data.map((d: any) => d.y));
  const maxY = Math.max(...data.map((d: any) => d.y));

  const lengthX = maxX - minX + 1;
  const lengthY = maxY - minY + 1;

  if (lands.length !== lengthX * lengthY) return false;

  for (let i = minX; i < minX + lengthX; i++) {
    let dataY = data
      .filter((d: any) => d.x === i)
      .map((d: any) => d.y)
      .sort();

    if (
      dataY.length === 0 ||
      dataY.length !== lengthY ||
      !isSequential(dataY)
    ) {
      return false;
    }
  }

  //FIXME
  for (let i = minY; i < minY + lengthY; i++) {
    let dataX = data
      .filter((d: any) => d.y === i)
      .map((d: any) => d.x)
      .sort();

    if (
      dataX.length === 0 ||
      dataX.length !== lengthX ||
      !isSequential(dataX)
    ) {
      return false;
    }
  }
  return true;
};

const isSequential = (data: number[]) => {
  return data.every(
    (num, i) => i === data.length - 1 || data[i + 1] - num === 1
  );
};

export const getImagePath = (imagePath: string) => {
  if (!imagePath) return "";

  return `${config.api_host}/${imagePath}`;
};

export const isStatusAvailable = (status: string) => {
  if (!status) return false;

  return SUMMAY_STATUS.AVAILABLE.includes(status);
};

export const getYoutubeVid = (url: string) => {
  var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  return match && match[1].length == 11 ? match[1] : "";
};

export const addFilterMap = (url: string, filterMap?: FilterMap) => {
  if (!filterMap) return url;

  let strUrlForSale = "";
  let strUrlAvailable = "";
  let strUrlWallet = "";
  let strUrlPartner = "";

  if (filterMap.forSaleChecked) {
    strUrlForSale = "&forsale=true";
  }

  if (filterMap.availableChecked) {
    strUrlAvailable = "&available=true";
  }

  if (filterMap.wallet) {
    strUrlWallet = `&wallet=${filterMap.wallet}`;
  }

  if (filterMap.partner) {
    strUrlPartner = `&wallet=${filterMap.partner}`;
  }

  return `${url}?${strUrlForSale}${strUrlAvailable}${strUrlWallet}${strUrlPartner}`;
};

export const getActionType = (
  status: string,
  account: string,
  walletAccount: string
) => {
  if (!walletAccount) return ACTION_TYPE.INFO;

  if (SUMMAY_STATUS.AVAILABLE.includes(status)) return ACTION_TYPE.BUY;

  if (SUMMAY_STATUS.ON_SELL.includes(status)) {
    return walletAccount !== account
      ? ACTION_TYPE.BUYFROM
      : ACTION_TYPE.CANCEL_SELL_LAND;
  } else if (SUMMAY_STATUS.OWN.includes(status) && walletAccount === account) {
    return ACTION_TYPE.SELL;
  }

  return ACTION_TYPE.INFO;
};

export const isFilterMap = (filter: FilterMap) => {
  return !(
    INITAIL_FILTER_MAP.availableChecked === filter.availableChecked &&
    INITAIL_FILTER_MAP.forSaleChecked === filter.forSaleChecked &&
    INITAIL_FILTER_MAP.myLandsChecked === filter.myLandsChecked &&
    INITAIL_FILTER_MAP.wallet === filter.wallet &&
    INITAIL_FILTER_MAP.partner === filter.partner
  );
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validURL = (str: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

/* export const convertMilisecToDate = (dateStr: string) => {
  const date = new Date(parseInt(dateStr));
  return date.toLocaleDateString("en-US");
};
 */
export const convertMilisecToDate = (unixTimestamp: string) => {
  const date = new Date(parseInt(unixTimestamp));

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  return `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year.toString().padStart(4, "0")}`;
};

export const convertMilisecToMMMMDate = (dateStr: string) => {
  const options: any = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const date = new Date(parseInt(dateStr));
  const formattedDate = date.toLocaleDateString("en-US", options);
  const [, month, day, year] = formattedDate.replace(/,/g, "").split(" ");

  const yearBC = date.getUTCFullYear();

  return `${day} ${month} ${yearBC.toString().padStart(4, "0")}`;
};

