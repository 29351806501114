/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useState } from "react";
import { UserInfo, WalletInfo } from "../../typings/model";
import { useRecoilValue } from "recoil";
import { userInfoState, walletInfoState } from "../../recoil/atom";
import {
  Toolbar,
  IconButton,
  Box,
  Drawer,
  CardMedia,
  Link,
  Button,
  Container,
} from "@mui/material";
import jakaverseLogo from "../../assets/images/jakaverse-logo.png";
import AppsIcon from "@mui/icons-material/Apps";
import SideMenu from "../SideMenu";
import { DRAWER_WIDTH } from "../../util/constant";
import UserNav from "../UserNav";
import Wallet from "../Wallet";
import TelegramIcon from "@mui/icons-material/Telegram";

const Header: FC = () => {
  const walletInfo = useRecoilValue<WalletInfo>(walletInfoState);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
            position: "static",
          },
        }}
      >
        <SideMenu onClick={handleDrawerToggle}></SideMenu>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
            position: "static",
          },
        }}
        open
      >
        <SideMenu></SideMenu>
      </Drawer>

      <Box
        sx={{
          position: { xs: "inherit", md: "absolute" },
          width: { xs: "inherit", md: "100%" },
          zIndex: 2,
        }}
      >
        <Container maxWidth={false}>
          <Toolbar
            sx={{
              pl: { xs: "0px", md: "110px" },
              mt: { xs: 0, md: 2 },
            }}
          >
            {/*  <Box
              sx={{
                pl: "60px",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            ></Box> */}

            <IconButton
              aria-label="open drawer"
              edge="start"
              size="large"
              onClick={handleDrawerToggle}
              sx={{ mr: { sm: 2 }, display: { md: "none" }, color: "#ffffff" }}
            >
              <AppsIcon sx={{ fontSize: "35px" }} />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Link href="/">
                <CardMedia
                  component="img"
                  alt="jakaverse logo"
                  sx={{
                    mt: { xs: "2px", md: 1 },
                    mb: "2px",
                    ml: { xs: "-10px", md: 0 },
                    width: { xs: "120px;", md: "202px;" },
                  }}
                  image={jakaverseLogo}
                />
              </Link>
              <Button
                startIcon={<TelegramIcon />}
                component={Link}
                href="https://t.me/jkcoin"
                target="_blank"
                rel="noreferrer"
                sx={{
                  display: { xs: "none", md: "flex" },
                  ml: { xs: 0, md: 3 },
                  mt: { xs: "6px", md: 0 },
                  background: "none",
                  border: "1px solid #FFFFFF",
                  color: "#fff",
                  padding: { xs: "2px 10px", md: "4px 21px" },
                  fontWeight: 600,
                  fontSize: { xs: "10px;", md: "14px;" },
                  lineHeight: "30px",
                  textDecoration: "none",
                }}
              >
                Join Our Telegram
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                marginLeft: "auto",
                flexDirection: "row",
                ["@media (max-width:20px)"]: { flexDirection: "column" },
              }}
            >
              <Box
                sx={{
                  padding: { xs: "0", md: "7px 0px" },
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                <Button
                  component={Link}
                  href="https://staking.jakaverse.com"
                  target="_blank"
                  rel="noreferrer"
                  sx={{
                    display: { xs: "flex", md: "flex" },
                    mt: { xs: 0, md: 0 },
                    mr: { xs: 2, md: 0 },
                    background: "none",
                    border: "1px solid #FFFFFF",
                    color: "#fff",
                    padding: { xs: "0px 3px", md: "4px 51px" },
                    fontWeight: 600,
                    fontSize: { xs: "12px;", md: "14px;" },
                    lineHeight: "30px",
                    textDecoration: "none",
                  }}
                >
                  Staking
                </Button>
              </Box>

              <Box
                sx={{
                  padding: { xs: "none", md: "2px 38px" },
                  fontWeight: 600,
                  fontSize: { xs: "12px;", md: "14px;" },
                  lineHeight: "20px",
                }}
              >
                <Wallet></Wallet>
              </Box>

              {walletInfo.account && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box
                    sx={{
                      ml: "auto",
                    }}
                  >
                    <UserNav account={walletInfo.account} />{" "}
                  </Box>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </Box>
    </>
  );
};

export default Header;
