import React, { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";
import { loadingState } from "../../recoil/atom";
import LoadingSpinner from "../LoadingSpinner";

const Loading: FunctionComponent = () => {
  const loading = useRecoilValue(loadingState);

  return <LoadingSpinner hide={!loading} />;
};

export default Loading;
