import { IconButton, SvgIcon } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FunctionComponent } from "react";

import iconAbout from "../../assets/images/menu/about.png";
import iconAlpha from "../../assets/images/menu/alpha.png";
import iconEvent from "../../assets/images/menu/event.png";
import iconGame from "../../assets/images/menu/game.png";
import iconHome from "../../assets/images/menu/home.png";
import iconLand from "../../assets/images/menu/land.png";
import iconMarket from "../../assets/images/menu/market.png";
import { ReactComponent as TelegramImage } from "../../assets/images/menu/telegram.svg";

import { NavLink } from "react-router-dom";
import { DRAWER_WIDTH } from "../../util/constant";

const useStyles = makeStyles({
  active: {
    background: "#a2010c !important",
    borderRadius: "10px !important",
    width: "80px",
    height: "80px",
  },
  nonActive: {
    "&MuiIconButton-root:hover": {
      background: "#000000",
    },
  },
});

interface SideMenuProps {
  onClick?: () => void;
}

const SideMenu: FunctionComponent<SideMenuProps> = ({ onClick }) => {
  const classes = useStyles();

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <Box
      component="nav"
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: { sm: 0 },
        zIndex: 3,
        position: "absolute",
        top: 0,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{
          position: "fixed",
          width: DRAWER_WIDTH,
          minHeight: "470px",
          height: "100vh",
          maxHeight: "800px",
          overflowY: "auto",
          background: "rgba(174, 0, 4, 0.4)",
          borderRadius: "0px 30px 30px 0px",
        }}
      >
        <Box>
          <IconButton
            sx={{ display: "flex", flexDirection: "column" }}
            component={NavLink}
            to="/home"
            activeClassName={classes.active}
            onClick={handleClick}
          >
            <img src={iconHome} width="30px" height="30px" alt="menu home" />
            <span
              style={{
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              Home
            </span>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            sx={{ display: "flex", flexDirection: "column" }}
            component={NavLink}
            to="/land"
            activeClassName={classes.active}
            onClick={handleClick}
          >
            <img src={iconLand} width="30px" height="30px" alt="menu Land" />
            <span
              style={{
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              Map
            </span>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            sx={{ display: "flex", flexDirection: "column" }}
            component={NavLink}
            to="/alpha"
            activeClassName={classes.active}
            onClick={handleClick}
          >
            <img src={iconAlpha} width="30px" height="30px" alt="menu Alpha" />
            <span
              style={{
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              World
            </span>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            sx={{ display: "flex", flexDirection: "column" }}
            component={NavLink}
            to="/market"
            activeClassName={classes.active}
            onClick={handleClick}
          >
            <img
              src={iconMarket}
              width="30px"
              height="30px"
              alt="menu market"
            />
            <span
              style={{
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              Market
            </span>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            sx={{ display: "flex", flexDirection: "column" }}
            component={NavLink}
            to="/event"
            activeClassName={classes.active}
            onClick={handleClick}
          >
            <img src={iconEvent} width="30px" height="30px" alt="menu event" />
            <span
              style={{
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              Event
            </span>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            sx={{ display: "flex", flexDirection: "column" }}
            component={NavLink}
            to="/game"
            activeClassName={classes.active}
            onClick={handleClick}
          >
            <img src={iconGame} width="30px" height="30px" alt="menu game" />
            <span
              style={{
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              Game
            </span>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            sx={{ display: "flex", flexDirection: "column" }}
            component={NavLink}
            to="/about"
            activeClassName={classes.active}
            onClick={handleClick}
          >
            <img src={iconAbout} width="30px" height="30px" alt="menu about" />
            <span
              style={{
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              About
            </span>
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <IconButton
            sx={{ display: "flex", flexDirection: "column" }}
            component={NavLink}
            to={{ pathname: "https://t.me/jkcoin" }}
            target="_blank"
            rel="noreferrer"
            activeClassName={classes.active}
          >
            <SvgIcon
              component={TelegramImage}
              fillOpacity="1"
              viewBox="0 0 25 32"
            />
            <span
              style={{
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              Telegram
            </span>
          </IconButton>
        </Box>
      </Grid>
    </Box>
  );
};

export default SideMenu;
