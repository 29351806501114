/* eslint-disable jest/no-mocks-import */
import axios from "axios";
import {
  Asset,
  LandData,
  LandDetail,
  Partner,
  ProfileResponse,
} from "../typings/model";
import config from "../config/config.json";
import { addFilterMap } from "../util/common";

export const apiAxios = axios.create({
  baseURL: `${config.api_host}/api`,
});

export const getLand = () => {
  return apiAxios.get<LandData[]>(`/land`);
};

export const getLandSector = (id: number) => {
  return apiAxios.get<LandData[]>(`/land/sector/${id}`);
};

export const updateSellDetail = (data: any) => {
  return apiAxios.post("/land-detail/updateselldetail", data);
};

export const updateDetail = (data: any) => {
  return apiAxios.post("/land-detail/updatebuydetail", data);
};

export const updateBuyFromDetail = (data: any) => {
  return apiAxios.post("/land-detail/updatebuyfromdetail", data);
};

export const updateCancelDetail = (data: any) => {
  return apiAxios.post("/land-detail/updatecanceldetail", data);
};

export const updateMergeDetail = (data: any) => {
  return apiAxios.post("/land-detail/updatemergedetail", data);
};

export const updateSplitDetail = (data: any) => {
  return apiAxios.post("/land-detail/updatesplitdetail", data);
};

export const submitEmailSignUpForm = (data: any) => {
  return apiAxios.post("/users", data);
};

export const submitEmailSignInForm = (data: any) => {
  return apiAxios.post("/users/login", data);
};

export const submitEmailWalletSignInForm = (data: any) => {
  return apiAxios.post("/users/walletupdatemail", data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const loginWallet = (data: any) => {
  return apiAxios.post("/users/loginwallet", data);
};

export const validateLoginWallet = (data: any) => {
  return apiAxios.post("/users/validateloginwallet", data);
};

export const getAssets = (filter?: any) => {
  return apiAxios.get<Asset[]>(addFilterMap("/assets", filter));
};

export const getUserInfo = () => {
  return apiAxios.get("/users/getuserinfo", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const submitResetPasswordForm = (data: any) => {
  return apiAxios.post("/users/forgotpassword", data);
};

export const submitNewPasswordForm = (data: any) => {
  return apiAxios.post("/users/newpassword", data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const submitResendEmailForm = (data: any) => {
  return apiAxios.post("/users/resendmail", data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const updateWallet = (data: any) => {
  return apiAxios.post("/users/updatewallet", data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const getProfile = () => {
  return apiAxios.get<ProfileResponse>("/users/profile", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const updateProfile = (data: any) => {
  return apiAxios.post(`/users/profile`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const updateAvatar = (id: number, file: any) => {
  let formData = new FormData();
  formData.append("file", file);

  return apiAxios.post(`/users/profile/${id}/image`, formData, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const getLandDetail = (tokenId: string) => {
  return apiAxios.get<LandDetail>(`/assets/${tokenId}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const updateLandDetail = (tokenId: string, data: any) => {
  return apiAxios.post(`/assets/${tokenId}`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const uploadLandImage = (tokenId: string, file: any) => {
  let formData = new FormData();
  formData.append("file", file);

  return apiAxios.post(`/assets/${tokenId}/image`, formData, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const removeLandImage = (tokenId: string) => {
  return apiAxios.delete(`/assets/${tokenId}/image`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const getPartners = () => {
  return apiAxios.get<Partner[]>(`/partners`);
};

export const subscribe = (data: any) => {
  return apiAxios.post(`/subscribe`, data);
};

export const sendContactUs = (data: any) => {
  return apiAxios.post(`/contactus`, data);
};
