import { createTheme } from "@mui/material/styles";

export const muiTheme = createTheme(
  {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            color: "#ffffff",
            borderRadius: 0,
            background: "#000000",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "bai-jamjuree",
            backgroundColor: "#F2050B",
            borderRadius: "40px",
            textTransform: "none",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#F2050B",
            },
          },
          outlined: {
            backgroundColor: "transparent",
            border: "1px solid #353535",
            "&:hover": {
              backgroundColor: "transparent",
              border: "1px solid #F2050B",
            },
          },
          text: {
            border: "none",
            background: "none",
            textDecoration: "underline",
            fontWeight: "400",
            "&:hover": {
              background: "none",
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontFamily: "bai-jamjuree",
            color: "#ffffff",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: "bai-jamjuree",
            color: "#ffffff",
          },
          body1: {
            fontSize: "14px",
            lineHeight: "20px",
          },
          h6: {
            fontSize: "16px",
            fontWeight: 600,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            color: "#ffffff",
            borderRadius: 0,
            background: "#000000",
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            background: "linear-gradient(90deg, #F2050B 0%, #000000 100%)",
            height: "3px",
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            //background: "rgba(67, 0, 5, 0.8)",
            background: "rgba(0, 0, 0, 0.8)",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: "#444",
            "&.Mui-focused": {
              // border: "2px solid #F2050B",
              border: "1px solid #353535",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          },
          sizeSmall: {
            fontSize: "14px",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontFamily: "bai-jamjuree",
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: "#ffffff",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: "bai-jamjuree",
            color: "#ffffff",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontFamily: "bai-jamjuree",
            color: "#ffffff",
            background: "#1E1E1E",
            border: "1px solid #353535",
            borderRadius: "5px",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "bai-jamjuree",
            color: "#ffffff",
          },
        },
      },
    },
  },
  { index: 2 }
);
