import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation from "./en/translation.json";

//import Backend from "i18next-http-backend";
//import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
export const resources = {
  en: {
    translation,
  },
} as const;

i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      //wait: true,
    },
    resources,
  }, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
    t('key'); // -> same as i18next.t
});

export default i18n;
