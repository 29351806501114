export const IS_ALLOW_FEATURE_MENU = false;
export const MAX_SHOW_JAKA = 10;
export const MIN_BUY_SELL_JAKA = 2;
export const MAX_BUY_SELL_JAKA = 50;
export const MIN_X_BLOCKS_FEATURE_UPLOAD_IMAGE = 3;
export const MIN_Y_BLOCKS_FEATURE_UPLOAD_IMAGE = 3;

export const CANVAS_OFFSET_X = 200; //250 //310;
export const CANVAS_OFFSET_Y = 340; //360;

export const CANVAS_VIRTUAL_WIDTH = 150; //20
export const CANVAS_VIRTUAL_HEIGHT = 150; //20
export const SIZE_OF_BLOCK = 1;
export const THICKNESS = 0.1;
export const MAX_ZOOM_SCALE = 30;
export const MIN_ZOOM_SCALE = 1.65;
export const SCENSE_SCALE_DEFUALT_MOBILE = 2.65;

export const MAX_ZOOM_SCALE_MOBILE = 30;
export const MIN_ZOOM_SCALE_MOBILE = 0.75;

export const SCALE_BY = 2;

export const DRAWER_WIDTH = 90;
export const HEADER_HIGHT = "90px";

export const LOCAL_HOST = "/";

export const SELECTED_BLOCK_COLOR = "#e900ff";
export const BASE_COLOR_BLOCK = "#11468F";
export const BASE_CLOSED_COLOR_BLOCK = "#666666";
export const REGULAR_AVAILABLE_COLOR_BLOCK = "#146356";
export const REGULAR_OWNED_COLOR_BLOCK = "#dd4a48";
export const REGULAR_CLOSED_COLOR_BLOCK = "#666666";
export const REGULAR_ON_SELL_COLOR_BLOCK = "#ff9900";
export const PREMIUM_AVAILABLE_COLOR_BLOCK = "#ffc900";
export const PREMIUM_OWNED_COLOR_BLOCK = "#ff008e";
export const PREMIUM_CLOSED_COLOR_BLOCK = "#4A3933";
export const PREMIUM_ON_SELL_COLOR_BLOCK = "#f90716";
export const YOUR_REGULAR_COLOR_BLOCK = "#28FFBF";
export const YOUR_PREMIUM_COLOR_BLOCK = "#F2F5C8";
export const DIAMOND_AVAILABLE_COLOR_BLOCK = "#d407d2";

export const LAND_STATUS = {
  BASE: "xx",
  REGULAR_AVAILABLE: "ra",
  REGULAR_OWNED: "rw",
  REGULAR_CLOSED: "rc",
  REGULAR_ON_SELL: "rs",
  PREMIUM_AVAILABLE: "pa",
  PREMIUM_OWNED: "pw",
  PREMIUM_CLOSED: "pc",
  PREMIUM_ON_SELL: "ps",
  DIAMOND_AVAILABLE: "da",
  DIAMOND_OWNED: "pw",
  DIAMOND_CLOSED: "pc",
  DIAMOND_ON_SELL: "ds",
  YOUR_REGULAR: "yy",
  YOUR_PREMIUM: "zz",
};

export const SUMMAY_STATUS = {
  AVAILABLE: [
    LAND_STATUS.REGULAR_AVAILABLE,
    LAND_STATUS.PREMIUM_AVAILABLE,
    LAND_STATUS.DIAMOND_AVAILABLE,
  ],
  ON_SELL: [
    LAND_STATUS.REGULAR_ON_SELL,
    LAND_STATUS.PREMIUM_ON_SELL,
    LAND_STATUS.DIAMOND_ON_SELL,
  ],
  OWN: [
    LAND_STATUS.REGULAR_OWNED,
    LAND_STATUS.PREMIUM_OWNED,
    LAND_STATUS.DIAMOND_OWNED,
  ],
};

export const TYPEBLOCK = {
  REGULAR: "regular",
  PREMIUM: "premium",
  DIAMOND: "diamond",
};

export const DIALOG_TYPE = {
  BUY: "buy",
  SELL: "sell",
  INFO: "info",
  BUYFROM: "buyfrom",
  SPLIT: "split",
  MERGE: "merge",
  GET_BUY_FROM_PRICE: "getBuyFromPrice",
  CANCEL_SELL_LAND: "cancelSellLand",
  SET_BUY_PRICE: "setBuyPrice",
};

export const ACTION_TYPE = {
  BUY: "buy",
  SELL: "sell",
  INFO: "info",
  BUYFROM: "buyfrom",
  SPLIT: "split",
  MERGE: "merge",
  GET_BUY_FROM_PRICE: "getBuyFromPrice",
  CANCEL_SELL_LAND: "cancelSellLand",
  SET_BUY_PRICE: "setBuyPrice",
};

export const CURRENCY = {
  JK: "jk",
  USDT: "usdt",
};

export const CURRENCY_DISPLAY = {
  JK: "JK",
  USDT: "USDT",
};

export const COLOR_TABLE = [
  {
    status: LAND_STATUS.BASE,
    statusText: "base",
    background: BASE_COLOR_BLOCK,
    show: false,
  },
  {
    status: LAND_STATUS.REGULAR_AVAILABLE,
    statusText: "Regular Available",
    background: REGULAR_AVAILABLE_COLOR_BLOCK,
    show: true,
  },
  {
    status: LAND_STATUS.PREMIUM_AVAILABLE,
    statusText: "Premium Available",
    background: PREMIUM_AVAILABLE_COLOR_BLOCK,
    color: "#444444",
    show: true,
  },
  {
    status: LAND_STATUS.DIAMOND_AVAILABLE,
    statusText: "Diamond Available",
    background: DIAMOND_AVAILABLE_COLOR_BLOCK,
    show: false,
  },
  {
    status: LAND_STATUS.REGULAR_OWNED,
    statusText: "regular owned",
    background: REGULAR_OWNED_COLOR_BLOCK,
    show: false,
  },
  {
    status: LAND_STATUS.REGULAR_ON_SELL,
    statusText: "regular on sell",
    background: REGULAR_ON_SELL_COLOR_BLOCK,
    show: false,
  },
  {
    status: LAND_STATUS.PREMIUM_OWNED,
    statusText: "premium owned",
    background: PREMIUM_OWNED_COLOR_BLOCK,
    show: false,
  },
  {
    status: LAND_STATUS.PREMIUM_CLOSED,
    statusText: "premium closed",
    background: PREMIUM_CLOSED_COLOR_BLOCK,
    show: false,
  },
  {
    status: LAND_STATUS.PREMIUM_ON_SELL,
    statusText: "premium on sell",
    background: PREMIUM_ON_SELL_COLOR_BLOCK,
    show: false,
  },
  {
    status: LAND_STATUS.YOUR_REGULAR,
    statusText: "your regular jaka",
    background: YOUR_REGULAR_COLOR_BLOCK,
    color: "#444444",
    show: false,
  },
  {
    status: LAND_STATUS.YOUR_PREMIUM,
    statusText: "your premium jaka",
    background: YOUR_PREMIUM_COLOR_BLOCK,
    color: "#444444",
    show: false,
  },
  {
    status: LAND_STATUS.REGULAR_CLOSED,
    statusText: "Land Closed",
    background: REGULAR_CLOSED_COLOR_BLOCK,
    show: true,
  },
];

export const INITAIL_FILTER_MAP = {
  forSaleChecked: false,
  availableChecked: false,
  myLandsChecked: false,
  wallet: "",
  partner: "",
};
