/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, CardMedia, Grid, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FC } from "react";
import jakaverseLogo from "../../assets/images/jakaverse-logo.png";
import channels from "../../content/ourChannel.json";
import { attributes } from "../../content/whitepaper/whitepaper.md";
import SocialMenuItem from "../SocialMenuItem";

const useStyles = makeStyles({
  iconSocial: {
    width: "100%",
    maxWidth: "55px",
  },
});

const Footer: FC = () => {
  const classes = useStyles();

  const { whitepaper } = attributes;

  const importAll = (r: any) => r.keys().map(r);
  const markdownFiles = importAll(
    require.context("../../content/ourchannel", false, /\.md$/)
  );

  return (
    <Box sx={{ px: { xs: 4, md: "100px" }, mb: 5, flexGrow: 1, color: "#fff" }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, md: 12 }}>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          textAlign="center"
          sx={{ pb: { xs: "16px", sm: "0px" } }}
        >
          <CardMedia
            component="img"
            alt="jakaverse image section2.1"
            image={jakaverseLogo}
            sx={{ width: { xs: "185px", md: "100%" } }}
          />
        </Grid>
        <Grid item container xs={12} md={6} justifyContent="space-around">
          <Grid item xs={6} sm={3} md={3}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "20px",
                pb: 1,
              }}
              component="h2"
              gutterBottom
            >
              Exchange
            </Typography>
            <Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="https://pancakeswap.finance/swap?outputCurrency=0x1eC58Fe5e681E35e490B5D4cBECdF42B29C1B063"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  Pancakeswap
                </Link>
              </Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="https://www.hotbit.io/exchange?symbol=JK_USDT"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  Hotbit
                </Link>
              </Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="https://www.lbank.info/exchange/jk/usdt"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  LBank
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "20px",
                pb: 1,
              }}
              component="h2"
              gutterBottom
            >
              Graph
            </Typography>
            <Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="https://poocoin.app/tokens/0x1ec58fe5e681e35e490b5d4cbecdf42b29c1b063"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  PooCoin
                </Link>
              </Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="/underconstruction"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  Trading View
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={3}
            sx={{ pt: { xs: "20px", sm: "0px" } }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "20px",
                pb: 1,
              }}
              component="h2"
              gutterBottom
            >
              Token
            </Typography>
            <Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="bsc scan = https://www.bscscan.com/token/0x1ec58fe5e681e35e490b5d4cbecdf42b29c1b063"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  BSC Scan
                </Link>
              </Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="coinmarketcap = https://coinmarketcap.com/currencies/jk-coin/"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  Coinmarketcap
                </Link>
              </Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="https://www.coingecko.com/th/coin/jk-coin"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  Coingecko
                </Link>
              </Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="https://www.certik.com/projects/jk-coin"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  Certik
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={3}
            sx={{ pt: { xs: "20px", sm: "0px" } }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "20px",
                pb: 1,
              }}
              component="h2"
              gutterBottom
            >
              Media
            </Typography>
            <Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="/announcements"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  Announcements
                </Link>
              </Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="/blog"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  Blog
                </Link>
              </Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="/howto"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  How To
                </Link>
              </Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href={whitepaper}
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  Whitepaper
                </Link>
              </Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="/faqs"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  FAQs
                </Link>
              </Box>
              <Box sx={{ py: 1 }}>
                <Link
                  href="/contactus"
                  underline="none"
                  sx={{ color: "#B9B9B9", fontSize: "14px" }}
                >
                  Contact Us
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "20px",
              pb: 1,
              pt: { xs: "20px", sm: "0px" },
            }}
            component="h3"
            gutterBottom
          >
            Social Media
          </Typography>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ pt: "10px" }}
          >
            {channels.map((channel) => (
              <Grid item xs={3} sm={3} md={3} key={channel.type}>
                <SocialMenuItem type={channel.type} links={channel.links} />
              </Grid>
            ))}
          </Grid>
          <Grid container sx={{ pt: "32px" }}>
            <Grid item xs={12}>
              <Typography sx={{ color: "#B9B9B9", fontSize: "12px" }}>
                Copyright © 2022 Jakaverse All Rights Reserved
              </Typography>
            </Grid>
            <Grid item xs={12} flexDirection="row" sx={{ pt: "16px" }}>
              <Link
                href="/termsofuse"
                underline="none"
                sx={{ color: "#B9B9B9", fontSize: "12px" }}
              >
                Terms of Use
              </Link>

              <Typography
                sx={{ color: "#B9B9B9", fontSize: "12px", px: 2 }}
                component="span"
              >
                |
              </Typography>
              <Link
                href="/policy"
                underline="none"
                sx={{ color: "#B9B9B9", fontSize: "12px" }}
              >
                Privacy Policy
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
